import React from 'react';
import ButtonDisabled from '../components/Button/ButtonDisabled';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import { withPrefix } from 'gatsby';
import poster from '../img/posterwebinar.png';

const primecolor = {
  color : '#0C41B8'
}

const Firstevent = () => (
  <Layout withRegister>
    <SEO title="Webinar" />
    <div className="p-3 md:p-0 md:container md:mx-auto w-full grid grid-cols-1 md:grid-cols-2 gap-12">
      <div className="p-2 md:p-10">
        <img src={ poster } alt="poster" className="mx-auto" />
      </div>
      <div className="p-2 md:p-10">
        <p className="font-bold text-2xl md:text-4xl">Webinar Path Towards Energy Transition in Indonesia</p><br />
        <p className="font-bold text-2xl md:text-2xl" style={ primecolor }>Webinar</p>
        <p className="text-base md:text-lg mt-4">
          Saturday , 22 May 2021 <br />
          13.00 - 16.00 WIB
        </p>
        <p className="font-bold text-2xl md:text-2xl mt-8" style={ primecolor }>Description :</p>
        <p className="text-base md:text-lg">
          a webinar with the theme of the energy transition in Indonesia which will discuss decarbonization, 
          the efforts that have been and will be used to support the transition process.
        </p>
        <p className="font-bold text-2xl md:text-2xl mt-8" style={ primecolor }>Benefit :</p>
        <div className="text-base md:text-lg ml-4">
          <ul className="list-disc">
            <li>Update knowledge about the progress of new renewable energy development in Indonesia from experts</li>
            <li>Get free e-certificate</li>
          </ul>
        </div>
        <div className="mt-5">
          <ButtonDisabled className="rounded-full">Register Now</ButtonDisabled>
        </div>
      </div>
    </div>
  </Layout>
);

export default Firstevent;
